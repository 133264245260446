import { recaptcha } from "@avldev/gatsby-active-campaign-forms"
import {
  cloudFunctionEndpoint,
  formAction,
  siteKey,
} from "../../config/recaptcha"

export const action = () => {
  return formAction(`residencesatbiltmore`)
}

export const initRecaptcha = () => {
  recaptcha.initRecaptchaV3(siteKey)
}

export const isSpam = async () => {
  return await recaptcha.looksLikeSpam(siteKey, cloudFunctionEndpoint)
}
